import { extendTheme } from '@chakra-ui/react';
import { ButtonStyles as Button } from '../../components/styles/ButtonStyles';
import { HeadingStyles as Heading } from '../../components/styles/HeadingStyles';
import {
  FormInputStyles as Input,
  FormLabelStyles as FormLabel,
  FormTextAreaStyles as TextArea,
  FormSelectStyles as Select,
} from '../../components/styles/FormStyle';
import { breakpoints } from '../../components/styles/Breakpoints';
import { TextStyles as Text } from '../../components/styles/TextStyles';
import { BorderStyles as Border } from '../../components/styles/BorderStyles';
import { SectionStyles as Container } from '../../components/styles/SectionStyles';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  id: 'base',
  styles: {
    global: {
      html: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: '"Manrope", sans-serif',
        overflowX: 'hidden',
        boxSizing: 'border-box',
        overflowY: 'scroll',
      },
      body: {
        margin: '0',
        fontSize: '1rem',
        color: 'text',
        lineHeight: '1.5rem',
        fontWeight: 'normal',
        wordWrap: 'break-word',
        fontKerning: 'normal',
        fontFeatureSettings: '"kern", "liga", "clig", "calt"',
      },
      '*': {
        boxSizing: 'inherit',
      },
      '*_before': {
        boxSizing: 'inherit',
      },
      '*_after': {
        boxSizing: 'inherit',
      },
      '@media print': {
        'footer, aside, .nav-toggle': {
          display: 'none !important',
        },
        '@page': {
          size: 'auto',
          margin: '0mm',
        },
      },
      audio: {
        marginTop: '1rem',
        marginBottom: '1rem',
        display: 'block',
        width: '100%',
      },
      button: {
        color: 'inherit',
      },
      '.hs-form-field label': {
        marginBottom: '4px',
      },
      '.hs-input': {
        borderRadius: '4px',
        border: 'solid 1px #6c6c6c',
        bg: '#ffffff',
        width: '100%',
        padding: '11px 15px',
        marginBottom: '16px',
      },
      '.legal-consent-container p': {
        fontSize: '0.8889rem',
        lineHeight: '1.111rem',
      },
      '.hs-button': {
        padding: '10px 24px',
        transform: 'perspective(1px) translateZ(0)',
        transitionDuration: '0.3s',
        textTransform: 'titlecase',
        background: 'primary',
        color: '#ffffff',
        border: 'none',
        borderRadius: '4px',
        fontWeight: '600',
        cursor: 'pointer',
        _hover: {
          bg: 'primary',
        },
      },
      '.image-left': {
        marginBottom: '1.5rem',
        '@media only screen and (min-width: 600px)': {
          float: 'left',
          width: '40%',
          margin: '0 1.5rem 1.5rem 0',
        },
      },
      '.image-right': {
        marginBottom: '1.5rem',
        '@media only screen and (min-width: 600px)': {
          float: 'right',
          width: '40%',
          margin: '0 0 1.5rem 1.5rem',
        },
      },
      '.fitVids-wrapper': {
        position: 'relative',
        paddingBottom: '56.25%',
        height: '0',
        margin: '1.5rem 0',
      },
      '.fitVids-wrapper iframe': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
      },
      a: {
        bg: 'transparent',
        color: 'primary',
        textDecoration: 'text',
      },
      'a_hover, a_active': {
        outlineWidth: 0,
        textDecoration: 'underline',
      },
      'a_focus, input_focus, button_focus': {
        outline: '3px solid #f49b20',
      },
      img: {
        borderStyle: 'none',
        maxWidth: '100%',
        margin: '0',
        padding: '0',
        marginBottom: '1.45rem',
      },
      'svg:not(:root)': {
        overflow: 'hidden',
      },
      'button, input, optgroup, select, textarea': {
        font: 'inherit',
        margin: 0,
      },
      'article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary':
        {
          display: 'block',
        },
      'audio, canvas, progress, video': {
        display: 'inline-block',
      },
      'audio:not([controls])': {
        display: 'none',
        height: '0',
      },
      progress: {
        verticalAlign: 'baseline',
      },
      '[hidden], template': {
        display: 'none',
      },
      'abb[title]': {
        borderBottom: 'none',
        textDecoration: 'underline dotted',
      },
      'b, strong': {
        fontWeight: 'inherit bolder',
      },
      dfn: {
        fontStyle: 'italic',
      },
      mark: {
        bg: '#ff0',
        color: '#000',
      },
      small: {
        fontSize: '80%',
      },
      'sub, sup': {
        fontSize: '75%',
        lineHeight: '0',
        position: 'relative',
        verticalAlign: 'baseline',
      },
      sub: {
        bottom: '-0.25em',
      },
      sup: {
        top: '0.5em',
      },
      'code, kbd, pre, samp': {
        fontFamily: 'monospace, monospace',
        fontSize: '1em',
      },
      figure: {
        margin: '1em 40px',
      },
      optgroup: {
        fontWeight: '700',
      },
      'button, input': {
        overflow: 'visible',
      },
      'button, select': {
        textTransform: 'none',
      },
      fieldset: {
        border: '1px solid silver',
        margin: '0 2px',
        padding: '0.35em 0.625em 0.75em',
      },
      legend: {
        boxSizing: 'border-box',
        color: 'inherit',
        display: 'table',
        maxWidth: '100%',
        padding: '0',
        whiteSpace: 'normal',
      },
      textarea: {
        overflow: 'auto',
      },
    },
  },
  colors: {
    white: '#ffffff',
    black: '#333333',
    lightGrey: '#F4F5F6',
    darkGrey: '#5f5e5b',
    text: '#333333',
    // Blue
    primary: '#037fb0',
    primaryLight: '#F2F9FB',
    heading: '#25404e',
    // Dark blue
    secondary: '#25404e',
    highLights: {
      // Dark grey
      1: '#667983',
      // Normal blue
      2: '#0184B5',
      // Aqua blue
      3: '#07A699',
      // Peach
      4: '#F98786',
      // Orange
      5: '#F49B20',
      // Light grey
      6: '#BEC6CA',
    },
  },
  space: {
    0: '0px',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    16: '4rem',
    32: '8rem',
    64: '16rem',
  },
  shadows: {
    default: '0px 2px 4px rgba(0,0,0,0.18)',
    card: '0 0 4px rgba(0, 0, 0, .125)',
    navShadow: '9px 8px 7px -2px rgba(227,224,227,0.84)',
  },
  fonts: {
    body: '"Manrope"',
    heading: '"Manrope"',
  },
  lineHeights: {
    0: '1.111rem', // 20px - #0 Small
    1: '1.389rem', // 25px - #1 h3 mobile
    2: '1.5rem', // 27px - #2 Body, p, sub, sub mobile, h4 mobile, h3 mobile
    3: '1.667rem', // 30px - #3 h2 mobile
    4: '1.944rem', // 35px - #4 h4
    5: '2.222rem', // 40px - #5 h5, hero mobile
    5.5: '2.5rem',
    6: '2.778rem', // 50px - #6 h3
    7: '3.611rem', // 65px - #7 h2
    8: '4rem', // 75px - #8 h1
    9: '5rem',
  },
  fontSizes: {
    0: '0.8889rem', // 16px small #0
    1: '1rem', // 18px base font - #1 p and body, h4 mobile, h5 mobile
    2: '1.111rem', // 20px - #2 h3 mobile
    3: '1.389rem', // 25px - #3 h4, h2 mobile
    4: '1.667rem', // 30px - #4 h5,
    5: '1.944rem', // 35px - #5 H1 Mobile
    6: '2.222rem', // 40px - #6 h3
    7: '3.056rem', // 55px - #7 h2,
    8: '3.72rem', // 70px - #8 h1
  },
  fontWeights: {
    // normal
    0: '400',
    // medium
    1: '500',
    // semibold
    2: '600',
    // extrabold
    3: '800',
  },
  sizes: {
    full: '100%',
    maxWidth: '1110px',
  },
  radii: {
    default: '4',
    round: '15',
  },
  components: {
    Button,
    breakpoints,
    Heading,
    Input,
    FormLabel,
    TextArea,
    Select,
    Text,
    Border,
    Container,
  },
});

export default extendTheme(theme);
