import React from 'react';
import { Container, Flex } from '@chakra-ui/react';

type Props = {
  /** Control the width of the flex container */
  flexBasis?: string;
  /** Set the max width of the container */
  maxWidth?: string[] | string;
  /** Any additional props */
  props?: {};
  sx?: {};
  mx?: {};
  mb?: {};
  mt?: {};
  px?: {};
  pt?: {};
  pb?: {};
  style?: {};
  fontSize?: {};
  justifyContent?: {};
  width?: {};
  className?: string;
  fontWeight?: number;
  p?: {};
  variant?: string;
};

/** A simple container component for use inside a section. Uses Chakra UI Flex component */
const Containers: React.FC<Props> = ({
  flexBasis = '100%',
  maxWidth,
  variant,
  pt,
  ...props
}) => (
  <Container
    display="flex"
    px={[3, 3, 3, 0]}
    flexWrap="wrap"
    {...props}
    maxWidth="maxWidth"
    variant={variant}
    pt={pt}
  />
);

export default Containers;
