export const ButtonStyles = {
  // Styles all buttons have in common
  baseStyle: {
    cursor: 'pointer',
    fontWeight: 2,
    fontSize: 1,
    px: '24px',
    py: '10px',
    transform: 'perspective(1px) translateZ(0)',
    transitionDuration: '0.3s',
  },
  // Different button sizes
  sizes: {},
  // Different button variants
  variants: {
    primary: {
      bg: 'primary',
      color: 'white',
      ':hover': {
        textDecoration: 'none',
      },
      textTransform: 'titlecase',
      size: 'lg',
    },
    primaryDark: {
      bg: 'white',
      color: 'primary',
      ':hover': {
        bg: 'primaryLight',
        color: 'primary',
      },
      textTransform: 'titlecase',
      size: 'lg',
    },
    ghost: {
      background: '#fff',
      color: 'primary',
      ':hover': {
        bg: 'primaryLight',
        borderStyle: 'none',
        color: 'primary',
      },
      textTransform: 'titlecase',
      border: '1px solid',
      borderColor: 'primary',
      size: 'lg',
    },
    secondary: {
      color: 'white',
      backgroundColor: 'secondary',
      size: 'lg',
    },
    adminBarButton: {
      display: ['block', 'inline-block'],
      textAlign: 'right',
      mr: '8',
      p: '0',
      bg: 'transparent',
      size: 'lg',
    },
  },
  // Default size and or variant
  defaultProps: {
    variant: 'primary',
  },
};
