import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { chakra, Button } from '@chakra-ui/react';

const FixedBar = chakra('div', {
  baseStyle: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    padding: '16px',
    borderTop: '1px solid #dfe3e5',
    boxShadow: '0 -1px 7px rgba(0, 0, 0, 0.12)',
    background: '#fff',
    fontSize: '13px',
    zIndex: '999999999999999',
    '@media only screen and (min-width: 600px)': {
      bottom: '0',
      top: 'auto',
      position: 'fixed',
      left: '0',
    },
    a: {
      color: 'colors.primary',
      textDecoration: 'underline',
    },
  },
});

type Props = {
  setShowCookieBar: (text: boolean) => void;
};

/** Shows the cookie tracking warning on the bottom of the website. */
const CookieBar = ({ setShowCookieBar }: Props): JSX.Element => (
  <FixedBar role="dialog">
    <div>
      SpotCast uses cookies to give you the best experience on our website. For
      more details see our <Link to="/privacy-policy">Privacy Policies</Link>.
      <span>
        <Button
          m={['10px auto 0', '0 0 0 56px']}
          variant="primary"
          display={['block', 'inline-block']}
          onClick={() => {
            setShowCookieBar(false);
          }}
        >
          Got it!
        </Button>
      </span>
    </div>
  </FixedBar>
);

CookieBar.propTypes = {
  /** Function triggers state change as to if someone has accepted that we uses cookies. Will make component disappear */
  setShowCookieBar: PropTypes.func.isRequired,
};

export default CookieBar;
