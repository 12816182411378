import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@chakra-ui/react';
import Helmet from 'react-helmet';
import Footer from './Footer';
import { useLocalStorage } from '../../hooks/useLocalStorage';
// Theme and CSS

import CookieBar from './CookieBar';
import favicon from '../../images/SpotCast-Favicon-08.png';
import { generateUuid } from '../../lib/Utilties';

export const LocaleContext = React.createContext({
  locale: 'en',
  product: '',
  location: {},
});

/** Our main layout wrapper. This sets up our basic page structure, imports our theme, and has some basic state management for the cookie warning */
const Layout = ({
  children,
  pageContext: { locale, allLocales, slug, product },
  path,
  data,
  location,
}) => {
  const [localesWithIds, setLocalesWithIds] = useState([]);
  const [browserLocation, setBrowserLocation] = useLocalStorage(
    'browserLocation',
    'en'
  );

  const [showCookieBar, setShowCookieBar] = useLocalStorage(
    'showCookieBar',
    true
  );

  let parentSlug;

  if (data?.datoCmsFeaturePage?.treeParent) {
    if (data?.datoCmsFeaturePage?.treeParent.treeParent) {
      parentSlug = `${data?.datoCmsFeaturePage?.treeParent.treeParent.slug}/${data?.datoCmsFeaturePage?.treeParent.slug}/${data?.datoCmsFeaturePage?.slug}`;
    } else {
      parentSlug = `${data?.datoCmsFeaturePage?.treeParent.slug}/${data?.datoCmsFeaturePage?.slug}`;
    }
  } else {
    parentSlug = `${data?.datoCmsFeaturePage?.slug}`;
  }

  useEffect(() => {
    setBrowserLocation(locale);
  }, []);

  // Add unique keys for list rendering
  useEffect(() => {
    if (allLocales) {
      const locales = allLocales.map((locale: any, _: number) => ({
        ...locale,
        id: generateUuid(),
      }));

      setLocalesWithIds(locales);
    }
  }, [allLocales]);

  return (
    <LocaleContext.Provider value={{ locale, product, location }}>
      <>
        <Helmet>
          {localesWithIds?.map((locale: any, index: number) =>
            locale === 'en' ? (
              <link
                rel="alternate"
                key={locale.id}
                hrefLang="x-default"
                href={`https://www.safer.me/${parentSlug && parentSlug}${
                  slug ? `/` : ``
                }`}
              />
            ) : (
              <link
                rel="alternate"
                key={index}
                hrefLang={locale?.locale.toLowerCase()}
                href={`https://www.safer.me/${
                  locale ? `${locale?.locale.toLowerCase()}/` : ``
                }${parentSlug && parentSlug}${slug ? `/` : ``}`}
              />
            )
          )}

          <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
          <html lang="en" />
        </Helmet>
        {showCookieBar && <CookieBar setShowCookieBar={setShowCookieBar} />}
        <Box as="main">{children}</Box>
        <Container maxWidth="full" variant="footer">
          <Footer />
        </Container>
      </>
    </LocaleContext.Provider>
  );
};

Layout.propTypes = {
  /** The contents of the layout */
  children: PropTypes.node.isRequired,
  /** The page path. Used when there is no page context. Most likely a locally generated page */
  path: PropTypes.string.isRequired,
  /** Passes in any page context as required  */
  pageContext: PropTypes.object,
};

export default Layout;
