import React from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';

const H1: React.FC<HeadingProps> = ({ ...props }) => (
  <Heading as="h1" size="h1" {...props} />
);
const H2: React.FC<HeadingProps> = ({ ...props }) => (
  <Heading as="h2" size="h2" {...props} />
);
const H3: React.FC<HeadingProps> = ({ ...props }) => (
  <Heading as="h3" size="h3" {...props} />
);
const H4: React.FC<HeadingProps> = ({ ...props }) => (
  <Heading as="h4" size="h4" {...props} />
);
const H5: React.FC<HeadingProps> = ({ ...props }) => (
  <Heading as="h5" size="h5" {...props} />
);
const SubHeading: React.FC<HeadingProps> = ({ ...props }) => (
  <Heading as="p" size="p" {...props} />
);
const Small: React.FC<HeadingProps> = ({ ...props }) => (
  <Heading as="p" size="p" {...props} />
);

const HeadingStyles = {
  baseStyle: {
    fontSize: '18px',
  },
  sizes: {
    h1: {
      lineHeight: ['3', '7'],
      mb: ['1', '3'],
      fontSize: ['3', '7'],
      fontWeight: ['3'],
    },
    h2: {
      lineHeight: ['3', '7'],
      mb: ['1', '4'],
      fontSize: ['3', '7'],
      fontWeight: ['3'],
    },
    h3: {
      lineHeight: ['1', '6'],
      mb: ['1', '4'],
      fontSize: ['2', '6'],
      fontWeight: ['3'],
    },
    h4: {
      lineHeight: ['4'],
      mb: ['1', '4'],
      fontSize: ['1', '3'],
      fontWeight: ['0'],
    },
    h5: {
      lineHeight: ['2', '3'],
      mb: ['1', '4'],
      fontSize: ['1', '4'],
      fontWeight: ['2'],
      color: 'primary',
    },
    SubHeading: {
      lineHeight: ['1'],
      mb: ['1', '4'],
      fontSize: ['1'],
      fontWeight: ['3'],
      color: 'heading',
    },
    Small: {
      lineHeight: ['0'],
      mb: ['1', '4'],
      fontSize: ['0'],
      fontWeight: ['0'],
    },
  },
  defaultProps: {
    size: 'h1',
  },
};

export { HeadingStyles, H1, H2, H3, H4, H5, SubHeading, Small };
