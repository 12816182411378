export const generateUuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

// Find all videos in the document and wrap them in a div to adjust video size to fit page
export const adjustVideoSize = () => {
  const players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];
  const fitVids = document.querySelectorAll(players.join(','));
  if (fitVids.length) {
    // Loop through videos
    for (let i = 0; i < fitVids.length; i++) {
      // Get Video Information
      const fitVid = fitVids[i];
      const width = fitVid.getAttribute('width');
      const height = fitVid.getAttribute('height');
      const aspectRatio = height / width;
      const parentDiv = fitVid.parentNode;
      fitVid.loading = 'lazy';

      // Wrap it in a DIV
      const div = document.createElement('div');
      div.className = 'fitVids-wrapper';
      div.style.paddingBottom = `${aspectRatio * 100}%`;
      parentDiv.insertBefore(div, fitVid);
      fitVid.remove();
      div.appendChild(fitVid);

      // Clear height/width from fitVid
      fitVid.removeAttribute('height');
      fitVid.removeAttribute('width');
    }
  }
};
