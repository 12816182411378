import React from "react";
import Layout from "./src/components/ui/Layout";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { theme } from "./src/@chakra-ui/gatsby-plugin/theme";
import { Script } from "gatsby";

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => (
  <>
  
    <ChakraProvider resetCSS theme={theme}>
      <ColorModeScript initialColorMode="light" />
      <Script async src="//js.hs-scripts.com/4330484.js"></Script>
      <Layout {...props}>{element}</Layout>

    </ChakraProvider>
    
  </>
);

export default wrapPageElement;
